import React from "react";
import { Link } from "react-router-dom";

import { i18nMark, Trans } from "@lingui/react";

import * as S from "./styles";
import { IProps } from "./types";

// Define here possible titles so they can be extracted for translation
export const i18nPool = [
    i18nMark('Account'),
    i18nMark('Order History'),
    i18nMark('Address Book'),
    i18nMark('Payment Options'),
];

export const AccountMenu: React.FC<IProps> = ({ links, active }: IProps) => {
  return (
    <S.Wrapper>
      <S.MenuHeader><Trans id="MY ACCOUNT" /></S.MenuHeader>
      {links.map(link => {
        const menuItem = link
          .replace(/\//g, "")
          .replace("-", " ")
          .split(" ")
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
        return (
          <Link to={link} key={link}>
            <S.MenuItem active={active === link}><Trans id={menuItem} /></S.MenuItem>
          </Link>
        );
      })}
    </S.Wrapper>
  );
};
