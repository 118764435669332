import "./scss/index.scss";

import {I18n, Trans} from "@lingui/react";

import * as React from "react";

import { Button, Form, TextField } from "..";
import { maybe } from "../../core/utils";
import { TypedRequestPasswordResetMutation } from "./queries";

import { PASSWORD_RESET_URL } from "../../core/config";

const PasswordResetForm: React.FC = () => (
  <I18n>
    {({i18n}) => <div className="password-reset-form">
      <Trans id="Please provide us your email address so we can share you a link to reset your password" render="p" />
      <TypedRequestPasswordResetMutation>
        {(passwordReset, {loading, data}) => {
          return (
            <Form
              errors={maybe(() => data.requestPasswordReset.errors, [])}
              onSubmit={(event, {email}) => {
                event.preventDefault();
                passwordReset({variables: {email, redirectUrl: PASSWORD_RESET_URL}});
              }}
            >
              <TextField
                name="email"
                autoComplete="email"
                label={i18n._('Email Address')}
                type="email"
                required
              />
              <div className="password-reset-form__button">
                <Button type="submit" {...loading && {disabled: true}}>
                  {loading ? i18n._('Loading') : i18n._('Reset password')}
                </Button>
              </div>
            </Form>
          );
        }}
      </TypedRequestPasswordResetMutation>
    </div>
    }
  </I18n>
);

export default PasswordResetForm;
