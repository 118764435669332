import "./scss/index.scss";

import { I18n, Trans } from "@lingui/react";

import { History } from "history";
import * as React from "react";
import { AlertManager, useAlert } from "react-alert";
import { generatePath, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

import { Button, CartTable } from "../../../components";

import { CartContext } from "../../../components/CartProvider/context";
import { extractCheckoutLines } from "../../../components/CartProvider/utils";
import { orderConfirmationUrl } from "../../../routes";
import { StepCheck } from "../../components";
import { CheckoutContext } from "../../context";
import { paymentUrl } from "../../routes";
import { TypedCompleteCheckoutMutation } from "./queries";
import Summary from "./Summary";
import { completeCheckout } from "./types/completeCheckout";

const completeCheckout = (
  data: completeCheckout,
  history: History,
  clearCheckout: () => void,
  clearCart: () => void,
  alert: AlertManager
) => {
  const canProceed = !data.checkoutComplete.errors.length;

  if (canProceed) {
    const { token } = data.checkoutComplete.order;
    history.push({
      pathname: orderConfirmationUrl,
      state: { token },
    });
    clearCheckout();
    clearCart();
  } else {
    data.checkoutComplete.errors.map(error => {
      alert.show(
        { title: error.message },
        {
          type: "error",
        }
      );
    });
  }
};

const View: React.FC<RouteComponentProps<{ token?: string }>> = ({
  history,
  match: {
    path,
    params: { token },
  },
}) => {
  const alert = useAlert();
  const {
    captureAuthorized,
    cardData,
    dummyStatus,
    checkout,
    clear: clearCheckout,
    step,
  } = React.useContext(CheckoutContext);
  const { clear: clearCart } = React.useContext(CartContext);

  const [agreeWithTerms, setAgreeWithTerms] = React.useState(false);

  const stepCheck = (
    <StepCheck checkout={checkout} step={step} path={path} token={token} />
  );

  if (!checkout) {
    return stepCheck;
  }

  return (
    <>
      {stepCheck}
      <div className="checkout-review">
        <Link
          to={generatePath(paymentUrl, { token })}
          className="checkout-review__back"
        >
          <Trans id="Go back to the previous Step" />
        </Link>

        <div className="checkout__step checkout__step--inactive">
          <span>5</span>
          <h4 className="checkout__header"><Trans id="Review your order" /></h4>
        </div>

        <div className="checkout__content">
          <CartTable
            lines={extractCheckoutLines(checkout.lines)}
            subtotal={checkout.subtotalPrice.gross.localized}
            deliveryCost={checkout.shippingMethod.price.localized}
            discount={checkout.discount.amount ? checkout.discount.localized : null}
            totalCost={checkout.totalPrice.gross.localized}
          />
          <div className="checkout-review__content">
            <Summary
              checkout={checkout}
              captureAuthorized={captureAuthorized}
              cardData={cardData}
              dummyStatus={dummyStatus}
            />
            <div className="checkout-review__content__submit">
              <TypedCompleteCheckoutMutation
                onCompleted={data =>
                  completeCheckout(
                    data,
                    history,
                    clearCheckout,
                    clearCart,
                    alert
                  )
                }
              >
                {(completeCheckout, { loading }) => (
                  <>
                    <label className="checkbox">
                      <input
                        name="agreeTerms"
                        type="checkbox"
                        checked={agreeWithTerms}
                        onChange={({ target: { checked } }) => setAgreeWithTerms(checked)}
                      />
                      <span className="checkout__content__terms">
                        <Trans id="I have read the" />&nbsp;
                        <a href="/media/uploads/AGB_schungit_original.pdf" target="_blank">
                          <Trans id="terms and conditions" />
                        </a>&nbsp;
                        <Trans id="and agree." />
                      </span>
                    </label>
                    <Button
                      type="submit"
                      disabled={loading || !agreeWithTerms}
                      onClick={() =>
                        completeCheckout({
                          variables: {
                            checkoutId: checkout.id,
                          },
                        })
                      }
                    >
                      <I18n>
                        {({ i18n }) => loading ? i18n._('Loading') : i18n._('Place your order')}
                      </I18n>
                    </Button>
                  </>
                )}
              </TypedCompleteCheckoutMutation>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
