import {I18n} from "@lingui/react";
import * as React from "react";

import { CheckoutContext } from "../../context";
import { Checkout } from "../../types/Checkout";

import { TypedAddPromoCodeToCheckoutMutation } from "../../queries";

import { Button, Form, TextField } from "../../../components";

export interface IPromoForm {
  checkout: Checkout;
}

interface ICodeForm {
  promoCode: string;
}

const PromoForm: React.FC<IPromoForm> = ({checkout}) => {
  const [formErrors, setFormErrors] = React.useState<any[]>([]);

  return (
    <div className="promo-form">
      {!checkout.voucherCode && <CheckoutContext.Consumer>
        {({ update }) => (
          <TypedAddPromoCodeToCheckoutMutation
            onCompleted={async ({checkoutAddPromoCode: {checkout, errors}}) => {
              setFormErrors(errors);
              if (!errors.length) {
                await update({checkout});
              }
            }}
          >
            {(addPromoCode, { loading }) => (
              <I18n>
                {({i18n}) => <Form<ICodeForm>
                  errors={formErrors}
                  onSubmit={(evt, data) => {
                    evt.preventDefault();

                    addPromoCode({
                      variables: {
                        checkoutId: checkout.id,
                        promoCode: data.promoCode,
                      },
                    });
                  }}
                  className="promo-form__input"
                >
                  <TextField
                    type="text"
                    name="promoCode"
                    autoComplete="off"
                    required
                  />
                  <Button type="submit" disabled={loading}>
                    {loading ? i18n._('Loading') : i18n._('Use')}
                  </Button>
                </Form>}
              </I18n>
            )}
          </TypedAddPromoCodeToCheckoutMutation>
        )}
      </CheckoutContext.Consumer>}

      {checkout.voucherCode && (<div className="promo-form__code">{checkout.voucherCode}</div>)}
    </div>
  );
};
export default PromoForm;