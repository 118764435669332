import { smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import * as React from "react";
import Media from "react-media";

import { I18n, Trans } from "@lingui/react";

import CostRow from "./CostRow";
import ProductRow, { EditableProductRowProps, LineI } from "./ProductRow";

interface TableProps extends EditableProductRowProps {
  lines: LineI[];
  subtotal: string;
  deliveryCost?: string;
  discount?: string;
  totalCost?: string;
}

const Table: React.FC<TableProps> = ({
  subtotal,
  deliveryCost,
  discount,
  totalCost,
  lines,
  ...rowProps
}) => (
  <Media query={{ minWidth: smallScreen }}>
    {mediumScreen => (
      <I18n>
        {({i18n}) => <table className="cart-table">
          <thead>
          <tr>
            <th><Trans id="Products" /></th>
            {mediumScreen && <th><Trans id="Price" /></th>}
            <th><Trans id="Size" /></th>
            <th className="cart-table__quantity-header"><Trans id="Quantity" /></th>
            <th colSpan={2}>{mediumScreen ? i18n._('Total Price') : i18n._('Price')}</th>
          </tr>
          </thead>
          <tbody>
          {lines.map(line => (
            <ProductRow
              key={line.id}
              line={line}
              mediumScreen={mediumScreen}
              {...rowProps}
            />
          ))}
          </tbody>
          <tfoot>
          <CostRow
            mediumScreen={mediumScreen}
            heading={i18n._('Subtotal')}
            cost={subtotal}
          />
          {deliveryCost && (
            <CostRow
              mediumScreen={mediumScreen}
              heading={i18n._('Delivery Cost')}
              cost={deliveryCost}
            />
          )}
          {discount && (
            <CostRow
              mediumScreen={mediumScreen}
              heading={i18n._('Discount')}
              cost={`-${discount}`}
            />
          )}
          {totalCost && (
            <CostRow
                mediumScreen={mediumScreen}
                heading={i18n._('Total Cost')}
                cost={totalCost}
            />
          )}
          </tfoot>
        </table>
        }
      </I18n>
    )}
  </Media>
);

export default Table;
