import gql from "graphql-tag";
import { TypedMutation } from "../../core/mutations";
import { RequestResetPassword, RequestResetPasswordVariables } from "./types/RequestResetPassword";

const requestPasswordReset = gql`
  mutation ResetPassword($email: String!, $redirectUrl: String!) {
    requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {
      errors {
        field
        message
      }
    }
  }
`;

export const TypedRequestPasswordResetMutation = TypedMutation<
  RequestResetPassword,
  RequestResetPasswordVariables
>(requestPasswordReset);
