import "./scss/index.scss";

import classNames from "classnames";
import * as React from "react";

import { I18n } from "@lingui/react"

import { Button, Form, Select, TextField } from "..";
import { ShopContext } from "../ShopProvider/context";
import { FormAddressType, IShippingAddressFormProps } from "./types";
import { getFormData } from "./utils";

const ShippingAddressForm: React.FC<IShippingAddressFormProps> = ({
  data,
  buttonText,
  errors,
  loading,
  onSubmit,
  children,
  shippingAsBilling = false,
  type = "shipping",
}) => (
    <I18n>
      {({i18n}) => <div className="address-form">
        <ShopContext.Consumer>
          {({countries, geolocalization, defaultCountry}) => (
            <Form<FormAddressType>
                errors={errors}
                onSubmit={(evt, data) => {
                  evt.preventDefault();
                  onSubmit(data);
                }}
                data={getFormData(geolocalization, defaultCountry, data)}
            >
              {children}
              <fieldset disabled={shippingAsBilling}>
                <div className="address-form__grid">
                  <TextField
                      label={i18n._('First Name')}
                      type="given-name"
                      name="firstName"
                      autoComplete="given-name"
                      required
                  />
                  <TextField
                      label={i18n._('Last Name')}
                      type="family-name"
                      name="lastName"
                      autoComplete="family-name"
                      required
                  />
                </div>
                <div className="address-form__grid">
                  <TextField
                      label={i18n._('Street Name')}
                      type="address-line1"
                      name="streetAddress1"
                      autoComplete="address-line1"
                      required
                  />
                  <TextField
                      label={i18n._('Company name (optional)')}
                      type="organization"
                      name="companyName"
                      autoComplete="organization"
                  />
                </div>
                <div className="address-form__grid address-form__grid--uneven">
                  <TextField
                      label={i18n._('ZIP Code')}
                      type="postal-code"
                      name="postalCode"
                      autoComplete="postal-code"
                      required
                  />
                  <TextField
                      label={i18n._('City')}
                      type="city"
                      name="city"
                      autoComplete="address-level2"
                      required
                  />
                </div>
                <div className="address-form__grid">
                  <TextField
                      label={i18n._('State/Province (Optional)')}
                      type="state"
                      name="countryArea"
                      autoComplete="address-level1"
                  />
                  <Select
                      label={i18n._('Country')}
                      name="country"
                      options={countries.map(country => ({
                        label: country.country,
                        value: country.code,
                      }))}
                      autoComplete="country"
                  />
                </div>
                <div
                    className={classNames("address-form__grid", {
                      "address-form__grid--full": type === "billing",
                    })}
                >
                  {type === "shipping" && (
                      <TextField
                          label={i18n._('Email Address')}
                          type="email"
                          autoComplete="email"
                          name="email"
                          required
                      />
                  )}

                  <TextField
                      label={i18n._('Phone (Optional)')}
                      type="tel"
                      name="phone"
                      autoComplete="phone"
                  />
                </div>
              </fieldset>
              <Button type="submit" disabled={loading}>
                {loading ? i18n._('Loading') : buttonText}
              </Button>
            </Form>
          )}
        </ShopContext.Consumer>
      </div>
      }
    </I18n>
);

export default ShippingAddressForm;
